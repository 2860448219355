<template>
  <b-overlay :show="is_busy" rounded="sm">
    <div class="container-fluid">
      <section class="card p-2">    
        <div class="card-body" id="printMe">
          
          <div class="row">
            <div class="col-md-2">
              <img :src="school.logo" class="img-fluid" style="height:70px" v-if="school.logo">
            </div>
            <div class="col-md-9">
              <p class="text-center">
                <span style="font-size: 20px; font-weight: bold">{{ school.school_name }}</span><br> 
                {{ school.slogan }}<br>
                {{ school.address }}<br>
      
              </p>
            </div>
          </div>
        
          <div class="row">
              <div class="col-lg-3 col-md-3 col-sm-3 col-12"></div>
              <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                  <div class="p-5" style="text-align:center">
                 
                    <span v-if="profile.image">
                      <img class="img-fluid img-responsive thumbnail" :src="profile.image" alt="Profile picture" style="height:100px; display: block; margin-left: auto; margin-right: auto; width: 50%; border-radius:10px">
                    </span>

                    <h4 class="text-center mt-2">{{ this.profile.lastname }} {{ profile.firstname }} {{ profile.middlename }}</h4>
                  </div>
              </div> 

              <div class="col-md-6">
                      <div class="table-responsive">
                          <table class="table table-hover">
                            <tr>
                              <th>Staff ID</th>
                              <td>{{ profile.roll }}</td>
                            </tr>
                            
                            <tr>
                              <th>Date of Birth</th>
                              <td>{{ profile.dob | myDate }}</td>
                            </tr>

                            <tr>
                              <th>Gender</th>
                              <td>{{ profile.gender | capitalize }}</td>
                            </tr>

                             <tr>
                              <th>Email</th>
                              <td>{{ profile.email }}</td>
                            </tr>
                            
                            <tr>
                              <th>Phone</th>
                              <td>{{ profile.phone }}</td>
                            </tr>

                            <tr>
                              <th>Current Address</th>
                              <td>{{ profile.current_address }}</td>
                            </tr>

                            <tr>
                              <th>Parmanent Address</th>
                              <td>{{ profile.parmanent_address }}</td>
                            </tr>

                            <tr>
                              <th>Nationality (State of Origin)</th>
                              <td>{{ profile.country }} ({{ profile.get_state }})</td>
                            </tr>

                            <tr>
                              <th>LGA</th>
                              <td>{{ profile.get_city }}</td>
                            </tr>

                            <tr>
                              <th>Religion</th>
                              <td>{{ profile.religion | capitalize }}</td>
                            </tr>                     
                          </table>
                      </div>
                    </div>

                    <div class="col-md-6">
                        <div class="table-responsive">
                            <table class="table table-hover">
                              <tr>
                                <th>Date of Employment</th>
                                <td>{{ profile.doa | myDate }}</td>
                              </tr>

                              <tr>
                                <th>Subject</th>
                                <td>{{ profile.get_subject }}</td>
                              </tr>

                              <tr>
                                <th>Category</th>
                                <td>{{ profile.get_category }}</td>
                              </tr>

                              <tr>
                                <th>Position</th>
                                <td>{{ profile.get_position }}</td>
                              </tr>                   

                              <tr>
                                <th>Level</th>
                                <td>Level {{ profile.level }}</td>
                              </tr>

                              <tr>
                                <th>Last Degree Obtained</th>
                                <td>{{ profile.last_degree }}</td>
                              </tr>

                              <tr>
                                <th>Univsersity Attended</th>
                                <td>{{ profile.university_attended }}</td>
                              </tr>
                            </table>
                        </div>
                    </div> 
          </div>
        </div>
        <div class="card-footer">
          <button v-print="printObj" class="btn btn-info btn-sm float-right">Print Profile</button>
          <div id="loading" v-show="printLoading"></div>
        </div>
      </section>
    </div>
  </b-overlay>
</template>

<script>
  import axios from 'axios';
  export default {
    data(){
      return  { 
        guardian: '',     
        profile: '',
        is_busy: false,
        school:'',
        printLoading: true,
        printObj: {
          id: "printMe",
          extraCss: "https://cdn.jsdelivr.net/npm/bootstrap@4.6.2/dist/css/bootstrap.min.css",
          extraJs: "https://cdn.jsdelivr.net/npm/jquery@3.5.1/dist/jquery.slim.min.js",
          extraHead: '<meta http-equiv="Content-Language"content="zh-cn"/>',
          beforeOpenCallback (vue) {
            vue.printLoading = true
          },
          openCallback (vue) {
            vue.printLoading = false
          },
          closeCallback (vue) {
          }
        },
      }
    },

    created(){
      this.roleHelper(['1', '5']);
      this.loadPage();
    },

    methods: {
      loadPage(){
        if (this.is_busy) return;
        this.is_busy = true;
        
        this.id = this.$route.params.staff_id;    
        axios.get('/staff/' + this.id)
        .then((response) => {
            this.profile = response.data.data.profile;
            this.school = response.data.data.school;
        })
        .catch((err)=>{
            Swal.fire(
                "Error!",
                err.response.data.data.error,
                "error"
            );
            //this.$router.push({path: '/administration/staff' });
        })
        .finally(() => {
            this.is_busy = false;
        })
      },
    },
  }
</script>
